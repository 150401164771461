import axios from 'axios';

// 创建 axios 实例
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:8000/api/v1',
  timeout: 10000
});

// 定义不需要 token 刷新的路径
const skipAuthPaths = ['/login', '/register', '/auth/token/refresh/'];

// 检查 token 是否过期
function isTokenExpired(token) {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // 解码 JWT payload
    const expiry = payload.exp * 1000; // token 的过期时间（以毫秒为单位）
    return Date.now() > expiry; // 判断是否过期
  } catch (error) {
    console.error('Invalid token format', error);
    return true; // 如果解析失败，视为 token 已过期
  }
}

// 刷新 token 方法
async function refreshAccessToken(refreshToken) {
  try {
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/auth/token/refresh/`, {
      refresh: refreshToken
    });
    // 成功刷新 token，存储新的 access token
    const newAccessToken = response.data.access;
    localStorage.setItem('access_token', newAccessToken);
    return newAccessToken;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    // 如果刷新 token 失败，清理所有 token 并跳转到登录页面
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/login'; // 重定向到登录页
    throw error;
  }
}

// 请求拦截器，自动刷新访问 token
api.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');

  // 获取请求的相对路径，确保去掉 baseURL
  const relativePath = config.url.startsWith('/') ? config.url : `/${config.url}`;

  // 检查当前请求路径是否在跳过 token 刷新的路径列表中
  if (skipAuthPaths.includes(relativePath)) {
    return config; // 不进行 token 刷新
  }

  // 如果 access token 已过期且有 refresh token，则尝试刷新
  if (isTokenExpired(accessToken) && refreshToken) {
    try {
      console.log('Access token expired, attempting to refresh...');
      accessToken = await refreshAccessToken(refreshToken); // 刷新并获取新的 access token
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } catch (error) {
      // 如果刷新失败，抛出错误，终止请求
      console.error('Failed to refresh access token:', error);
      return Promise.reject(error);
    }
  } else if (accessToken) {
    // 如果 access token 未过期，则直接添加到请求头
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;