<template>
  <div class="register-page">
    <div class="weui-form">
      <div class="weui-form__text-area">
        <h2 class="weui-form__title">注册</h2>
        <p class="weui-form__desc">请输入您的手机号、密码和邀请码</p>
      </div>

      <!-- 手机号输入框 -->
      <div class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">手机号</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="phone" type="tel" class="weui-input" placeholder="请输入手机号" />
        </div>
      </div>

      <!-- 密码输入框 -->
      <div class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">密码</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="password" type="password" class="weui-input" placeholder="请输入密码" />
        </div>
      </div>

      <!-- 确认密码输入框 -->
      <div class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">确认密码</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="confirmPassword" type="password" class="weui-input" placeholder="请再次输入密码" />
        </div>
      </div>

      <!-- 邀请码输入框 -->
      <div v-if="!autoFilledInvitationCode" class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">邀请码</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="invitationCode" type="text" class="weui-input" placeholder="请输入邀请码" />
        </div>
      </div>

      <!-- 错误提示信息 -->
      <div class="weui-form__tips-area" v-if="errorMessage">
        <div class="weui-tips weui-tips_warn">{{ errorMessage }}</div>
      </div>

      <!-- 注册按钮 -->
      <div class="weui-form__opr-area">
        <button class="weui-btn weui-btn_primary" @click="register">注册</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';

export default {
  setup() {
    const phone = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const invitationCode = ref('');  // 确保邀请码正确绑定
    const errorMessage = ref('');
    const autoFilledInvitationCode = ref(false);
    const router = useRouter();

    // 获取 URL 中的参数，检测是否有邀请码
    const mounted = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const invitationCodeParam = urlParams.get('invitation_code');
      if (invitationCodeParam) {
        invitationCode.value = invitationCodeParam;
        autoFilledInvitationCode.value = true;
      }
    };

    const register = async () => {
      const phoneRegex = /^1[3-9]\d{9}$/; // 中国大陆手机号正则表达式

      // 实时验证手机号、密码和确认密码
      if (!phoneRegex.test(phone.value)) {
        errorMessage.value = '请输入有效的中国大陆手机号';
        return;
      }

      if (password.value.length < 6) {
        errorMessage.value = '密码必须大于6位数';
        return;
      }

      if (password.value !== confirmPassword.value) {
        errorMessage.value = '两次输入的密码不一致';
        return;
      }

      try {
        const response = await api.post('/user/register/', {
          phone: phone.value,
          password: password.value,
          invitation_code: invitationCode.value  // 确保邀请码正确传入
        });

        // 注册成功后保存JWT令牌并跳转到主页
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        router.push({ name: 'home' ,
          query:{registered:'success'}
        });
      } catch (error) {
        // 错误处理: 检查是否返回了详细的错误信息
        if (error.response) {
          if (error.response.data.invitation_code) {
            errorMessage.value = error.response.data.invitation_code[0];
          } else if (error.response.data.phone) {
            errorMessage.value = error.response.data.phone[0];
          } else if (error.response.status === 403) {
            errorMessage.value = '邀请权限已关闭，无法注册';
          } else {
            errorMessage.value = error.response.data.detail || '注册失败';
          }
        } else {
          errorMessage.value = '网络错误，请检查网络连接';
        }
      }
    };

    return {
      phone,
      password,
      confirmPassword,
      invitationCode,
      errorMessage,
      autoFilledInvitationCode,
      register,
      mounted,
    };
  },
  mounted() {
    this.mounted(); // 调用mounted方法检测是否有邀请码
  },
};
</script>

<style scoped>
.weui-form {
  max-width: 400px;
  margin: 20px auto;
}

.weui-btn_primary {
  background-color: #1AAD19; /* 微信绿色 */
}

.error {
  color: red;
}

.weui-tips_warn {
  color: red;
}

@media (max-width: 768px) {
  .weui-form {
    max-width: 100%;
    padding: 10px;
  }
}
</style>
