<template>
  <div class="bind-alipay-container">
    <!-- 面包屑导航 -->
    <div class="breadcrumb">
      <router-link to="/personalcenter">个人中心</router-link> › 
      <router-link to="/withdraw">提现</router-link> › 
      <span>绑定支付宝收款信息</span>
    </div>
    
    <!-- 支付宝Logo -->
    <div class="logo-container">
      <img src="@/assets/alipay_logo.svg" alt="Alipay Logo" class="alipay-logo"/>
    </div>
    
    <!-- 重要提示 -->
    <div class="important-notice">
      <p><strong>重要提示：</strong>手机号和姓名需要与支付宝一致，否则无法到账</p>
    </div>
    
    <!-- 表单部分 -->
    <div class="weui-cells weui-cells_form">
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">姓名</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="name" class="weui-input" placeholder="请输入姓名"/>
        </div>
      </div>
      
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">支付宝账号</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="alipayAccount" class="weui-input" placeholder="请输入支付宝账号"/>
        </div>
      </div>
    </div>
    
    <!-- 提交按钮 -->
    <div class="weui-btn-area">
      <button class="weui-btn weui-btn_primary" @click="confirmSubmit">提交绑定</button>
    </div>
    
    <!-- 错误提示 -->
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    
    <!-- 底部菜单栏 -->
    <BottomMenu />

    <!-- 确认提示框 -->
    <div v-if="showDialog" class="weui-dialog__wrapper">
      <div class="weui-dialog">
        <div class="weui-dialog__hd"><strong class="weui-dialog__title">确认信息</strong></div>
        <div class="weui-dialog__bd">
          <p>姓名: {{ name }}</p>
          <p>支付宝账号: {{ alipayAccount }}</p>
          <p style="color: red;">信息有误将影响提现到账</p>
        </div>
        <div class="weui-dialog__ft">
          <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="cancel">取消</a>
          <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="submit">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import BottomMenu from "@/components/BottomMenu.vue";
import api from '@/api';

export default {
  name: "BindAlipay",
  components: {
    BottomMenu,
  },
  setup() {
    const name = ref('');
    const alipayAccount = ref('');
    const errorMessage = ref('');
    const showDialog = ref(false);
    const router = useRouter();

    const validateForm = () => {
      const chineseRegex = /^[\u4e00-\u9fa5]+$/;
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!name.value || !alipayAccount.value) {
        errorMessage.value = "请填写完整信息";
        return false;
      }
      if (!chineseRegex.test(name.value)) {
        errorMessage.value = "请检查姓名是否正确";
        return false;
      }
      if (!phoneRegex.test(alipayAccount.value)) {
        errorMessage.value = "仅支持手机号作为支付宝账号";
        return false;
      }
      return true;
    };

    const confirmSubmit = () => {
      errorMessage.value = '';
      if (validateForm()) {
        showDialog.value = true;
      }
    };

    const submit = async () => {
      showDialog.value = false;
      try {
        const response = await api.post('/user/bind-alipay/', {
          name: name.value,
          account: alipayAccount.value,
          platform: 'alipay'
        });
        console.log(response.data.message);
        // 绑定成功后的处理逻辑
        showToast('绑定成功');
        setTimeout(() => {
          router.push('/personalcenter');
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data) {
          errorMessage.value = error.response.data.error || "绑定失败，请重试";
        } else {
          errorMessage.value = "绑定失败，请重试";
        }
      }
    };

    const cancel = () => {
      showDialog.value = false;
    };

    const showToast = (message) => {
      const toast = document.createElement('div');
      toast.className = 'weui-toast';
      toast.innerHTML = `
        <div class="weui-mask_transparent"></div>
        <div class="weui-toast__content">${message}</div>
      `;
      document.body.appendChild(toast);
      setTimeout(() => {
        document.body.removeChild(toast);
      }, 2000);
    };

    return {
      name,
      alipayAccount,
      errorMessage,
      showDialog,
      confirmSubmit,
      submit,
      cancel
    };
  }
};
</script>

<style scoped>
.bind-alipay-container {
  padding: 20px;
  background-color: #f2f2f2;
  min-height: 100vh;
}

.breadcrumb {
  margin-bottom: 15px;
  font-size: 14px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb span {
  color: #6c757d;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.alipay-logo {
  width: 100px;
  height: auto;
}

.important-notice {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.weui-btn-area {
  margin-top: 20px;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
  text-align: center;
}

.weui-dialog__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}

.weui-dialog {
  width: 80%;
  max-width: 300px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.weui-dialog__hd {
  padding: 10px;
  background: #f2f2f2;
  text-align: center;
}

.weui-dialog__bd {
  padding: 20px;
  text-align: center;
}

.weui-dialog__ft {
  display: flex;
  border-top: 1px solid #e5e5e5;
}

.weui-dialog__btn {
  flex: 1;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

.weui-dialog__btn_default {
  color: #333;
}

.weui-dialog__btn_primary {
  color: #007bff;
}

.weui-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
}

@media (max-width: 768px) {
  .bind-alipay-container {
    padding: 10px;
  }
}
</style>