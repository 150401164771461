//index.js
import { createRouter, createWebHistory } from 'vue-router';  // Vue 3 的引入方式
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from '@/views/RegisterPage.vue';
import MissionPage from '@/views/Mission.vue';
import PersonalCenter from '@/views/PersonalCenter.vue';
import CaptainCenter from '@/views/CaptainCenter.vue';
import BindAlipay from '@/views/BindAlipay.vue';
import UserWithdraw from '@/views/UserWithdraw.vue';
import UpgradeContact from '@/views/UpgradeContact.vue';
import WithdrawRecord from '@/views/WithdrawRecord.vue';

// 创建路由
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: { requiresAuth: true ,title: '首页'}

  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { title: '登录' }
  }
    ,
    {
        path: '/register',
        name: 'register',
        component: RegisterPage,
        meta: { title: '注册' }
      },
  { 
    path: '/mission',
    name: 'mission',
    component: MissionPage,
    meta: { title: '做' }
  },
  {
    path: '/personalcenter',
    name: 'personalcenter',
    component: PersonalCenter,
    meta: { title: '个人中心' }
  },
  {
    path: '/captaincenter',
    name: 'captaincenter',
    component: CaptainCenter,
    meta: { title: '团长中心' }
  },
  {
    path: '/bindalipay',
    name: 'bindalipay',
    component: BindAlipay,
    meta: { title: '绑定支付宝' }
  },
  {
    path: '/userwithdraw',
    name: 'userwithdraw',
    component: UserWithdraw,
    meta: { title: '提现' }
  },
  {
    path: '/upgradecontact',
    name: 'upgradecontact',
    component: UpgradeContact,
    meta: { title: '升级团长' }
  },
  {
    path:'/withdrawrecord',
    name:'withdrawrecord',
    component: WithdrawRecord,
    meta: { title: '提现记录' }
  }
];

// 使用 Vue 3 的 createRouter 和 createWebHistory
const router = createRouter({
  history: createWebHistory(),
  routes
});



// 全局路由守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('access_token'); // 假设通过 token 判断登录状态
  const defaultTitle = process.env.VUE_APP_DEFAULT_TITLE || '肥羊学堂'; // 统一的标题部分
  document.title = to.meta.title ? `${to.meta.title}-${defaultTitle} ` : defaultTitle; // 动态设置标题
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login'); // 如果页面需要登录且用户未登录，跳转到登录页面
  } else {
    next(); // 否则继续访问
  }
});

export default router;
