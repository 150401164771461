<template>
  <div class="task-container">
    <div class="notification-bar">
      <i class="weui-icon-info"></i>
      <span>通知：超过1分钟没找到，就放弃拿新的，不要浪费时间</span>
    </div>

    <div class="change-task-section">
      <button @click="replaceTask" class="weui-btn weui-btn_warn">更换新任务</button>
      <div class="timer">{{ timer }}</div>
    </div>

    <div v-if="task" class="task-details">
      <h3>{{ task.title }}</h3>
      <div class="product-image">
        <img :src="task.main_image_url" alt="Task Image" />
      </div>
    </div>

    <div class="copy-search-button">
      <button @click="copyTitle" class="weui-btn weui-btn_default">复制标题搜索</button>
    </div>

    <div class="save-image-button">
      <button @click="saveImage" class="weui-btn weui-btn_default">保存图片到相册</button>
    </div>

    <div class="speed-operation">
      <a href="#">使用两台手机加速操作 >></a>
    </div>

    <div class="submit-section">
      <textarea 
      v-model="clipboardData" 
      placeholder="粘贴内容" 
      rows="3" 
      class="weui-textarea"
      @focus="pasteClipboardContent"
      ></textarea>
      <div class="weui-textarea-counter"><span>{{ clipboardData.length }}</span>/300</div>
      <button @click="submitTask" class="weui-btn weui-btn_primary">粘贴>提交>下一条</button>
    </div> 

    <BottomMenu />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import BottomMenu from "@/components/BottomMenu.vue";
import api from '@/api';
import weui from 'weui.js';

export default {
  components: {
    BottomMenu,
  },
  name: "TaskPage",
  setup() {
    const task = ref(null);
    const timer = ref("10:00");
    const clipboardData = ref("");

    const showToast = (message) => {
      weui.toast(message, 2000);
    };

    const calculateRemainingTime = () => {
      if (task.value && task.value.pickup_time) {
        const pickupDateTime = new Date(task.value.pickup_time);
        const currentTime = new Date();
        const difference = pickupDateTime.getTime() + 10 * 60 * 1000 - currentTime.getTime();

        if (difference > 0) {
          const minutes = Math.floor(difference / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
          timer.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        } else {
          timer.value = "00:00";
          showToast("时间到！");
          redirectToHome();
        }
      }
    };

    const fetchTask = async () => {
      try {
        const response = await api.get('/user/task/info/');
        if (response.data.length === 0) {
          redirectToHome();
        } else {
          task.value = response.data[0];
          calculateRemainingTime();
        }
      } catch (error) {
        showToast("接口无法链接，请检查网络");
      }
    };

    const replaceTask = async () => {
      try {
        await api.post('/user/tasks/replace/');
        showToast("更换成功");
        await fetchTask();
      } catch (error) {
        showToast("更换任务失败，请重试");
        redirectToHome();
      }
    };

    const copyTitle = () => {
      if (task.value && task.value.title) {
        navigator.clipboard.writeText(task.value.title).then(() => {
          showToast('标题已复制！');
        }).catch(() => {
          showToast('复制标题失败！');
        });
      } else {
        showToast('标题为空，无法复制！');
      }
    };

    const saveImage = async () => {
      if (task.value && task.value.main_image_url) {
        try {
          const response = await fetch(task.value.main_image_url);
          const blob = await response.blob();
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `task_image_${Date.now()}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          showToast('图片已保存到相册！');
        } catch (error) {
          showToast('保存图片时出错，请重试！');
        }
      } else {
        showToast('图片链接为空，无法保存！');
      }
    };

    const submitTask = async () => {
      if (!clipboardData.value) {
        await pasteClipboardContent(); // 自动粘贴剪贴板内容
      }
      if (!clipboardData.value) {
        showToast("提交的链接无效，请包含有效的网址链接");
        return;
      }
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      if (!urlPattern.test(clipboardData.value)) {
        showToast("提交的链接无效，请包含有效的网址链接");
        return;
      }
      try {
        if (task.value && task.value.id) {
          // 提交任务
          await api.post(`/user/tasks/submit/${task.value.id}/`, { text: clipboardData.value });
          showToast("提交成功");
          //清空剪贴板内容
          clipboardData.value = "";
          // 请求领取新任务
          await api.post('/user/task/pickup/');
          showToast("新任务领取成功");

          // 刷新任务信息
          await fetchTask(); // 放在此处，确保任务信息更新后再显示成功消息
        } else {
          showToast("任务ID无效,无法提交");
        }
      } catch (error) {
        const errorMessage = error.response?.data?.detail || "提交失败，请重试";
        showToast(errorMessage);
      }
    };

    const pasteClipboardContent = async () => {
      try {
        const text = await navigator.clipboard.readText();
        clipboardData.value = text;
        showToast("剪贴板内容已粘贴");
      } catch (error) {
        showToast("无法读取剪贴板内容");
      }
    };

    const redirectToHome = () => {
      window.location.href = '/';
    };

    onMounted(fetchTask);

    const interval = setInterval(calculateRemainingTime, 1000);
    onBeforeUnmount(() => clearInterval(interval));

    return {
      task,
      timer,
      clipboardData,
      replaceTask,
      copyTitle,
      saveImage,
      submitTask,
      pasteClipboardContent,
    };
  }
};
</script>

<style scoped>
.task-container {
  padding: 20px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #eaf6ff;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

.change-task-section,
.copy-search-button,
.save-image-button,
.speed-operation,
.submit-section {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.product-image {
  width: 70vw;
  height: 70vw;
  max-width: 300px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
</style>