<template>
  <div class="user-withdraw">
    <!-- 通知栏 -->
    <div class="notification-bar">
      <i class="weui-icon-info"></i>
      <span>提现到账时间为48小时内</span>
    </div>

    <!-- 面包屑导航 -->
    <div class="breadcrumb">
      <router-link to="/personalcenter">个人中心</router-link> &gt;
      <span>提现</span>
    </div>

    <!-- 账户信息 -->
    <div class="weui-cells__title">账户信息</div>
    <div class="weui-cells">
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">{{ isLeader ? '团长钱包余额' : '钱包余额' }}</label>
        </div>
        <div class="weui-cell__bd">
          <p class="balance">{{ balance }}</p>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">姓名</label>
        </div>
        <div class="weui-cell__bd">
          <p>{{ name }}</p>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">支付宝</label>
        </div>
        <div class="weui-cell__bd">
          <p>{{ alipay }}</p>
        </div>
      </div>
    </div>

    <!-- 提现按钮 -->
    <div class="weui-btn-area">
      <button class="weui-btn weui-btn_primary" @click="handleWithdraw(isLeader ? 'leader' : 'user')">全额提现</button>
    </div>

    <div class="withdraw-note">
      {{ withdrawNote }}
    </div>

    <div class="withdraw-record">
      <router-link to="/withdraw-record">查看提现记录 &gt;&gt;&gt;</router-link>
    </div>

    <!-- 底部菜单 -->
    <BottomMenu />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BottomMenu from "@/components/BottomMenu.vue";
import api from '@/api';

export default {
  name: "UserWithdraw",
  components: {
    BottomMenu,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const balance = ref(0);
    const name = ref('');
    const alipay = ref('');
    const withdrawNote = ref('');
    const isLeader = ref(route.query.isLeader === 'true'); // 从路由查询参数获取用户角色

    const fetchWalletInfo = async () => {
      try {
        const response = await api.get('/user/wallet-info/');
        const data = response.data;
        balance.value = isLeader.value ? data.leader_balance : data.user_balance;
        name.value = data.withdrawal_accounts[0]?.name || '';
        alipay.value = data.withdrawal_accounts[0]?.account || '';
        withdrawNote.value = isLeader.value ? '团长每周日可提现' : '每月逢单日可提现';
      } catch (error) {
        if (error.response && error.response.status === 400) {
          showToast(error.response.data.error || "获取信息失败，请重试");
          setTimeout(() => {
            router.push('/bindalipay');
          }, 2000);
        } else {
          showToast("获取信息失败，请重试");
        }
      }
    };

    const handleWithdraw = async (role) => {
      // const today = new Date();
      // const day = today.getDate();
      // const dayOfWeek = today.getDay();

      // if (isLeader.value && dayOfWeek !== 0) {
      //   showToast("团长只能在周日提现");
      //   return;
      // }

      // if (!isLeader.value && day % 2 === 0) {
      //   showToast("只能在单日提现");
      //   return;
      // }

      try {
        await api.post('/user/withdraw/', { amount: 'all', role });
        showToast("提现成功");
        setTimeout(() => {
          router.push('/withdraw-record');
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data) {
          showToast(error.response.data.error || "提现失败，请重试");
        } else {
          showToast("提现失败，请重试");
        }
      }
    };

    const showToast = (message) => {
      const toast = document.createElement('div');
      toast.className = 'weui-toast';
      toast.innerHTML = `
        <div class="weui-mask_transparent"></div>
        <div class="weui-toast__content">${message}</div>
      `;
      document.body.appendChild(toast);
      setTimeout(() => {
        document.body.removeChild(toast);
      }, 2000);
    };

    onMounted(() => {
      fetchWalletInfo();
    });

    return {
      balance,
      name,
      alipay,
      withdrawNote,
      handleWithdraw,
      isLeader
    };
  }
};
</script>

<style scoped>
.user-withdraw {
  padding: 20px;
  background-color: #f5f5f5; /* 灰色背景 */
  min-height: 100vh; /* 铺满全部屏幕 */
}

.notification-bar {
  background-color: #eaf6ff;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.breadcrumb {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.weui-cells__title {
  font-size: 16px;
  color: #333;
}

.balance {
  color: green;
  font-size: 24px;
}

.withdraw-note,
.withdraw-record {
  text-align: center;
  margin: 20px 0;
}

.withdraw-record a {
  color: #000;
  font-weight: bold;
}

.weui-btn-area {
  text-align: center;
}

.weui-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
}

@media (max-width: 768px) {
  .user-withdraw {
    padding: 10px;
  }
}
</style>