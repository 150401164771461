<template>
    <div class="qrcode-page">
      <!-- 二维码区域 -->
      <div class="qrcode-container">
        <img class="qrcode-img" src="@/assets/upgrade_qr_code.jpeg" alt="客服微信二维码" />
      </div>
      <!-- 信息提示条 -->
      <div class="info-bar">
        如需升级团长请添加客服微信
      </div>
      <!-- 底部菜单 -->
      <BottomMenu />
    </div>
  </template>
  
  <script>
  import BottomMenu from "@/components/BottomMenu.vue";
  
  export default {
    name: "QrCodePage",
    components: {
      BottomMenu,
    },
  };
  </script>
  
  <style scoped>
  .qrcode-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f0f0;
    position: relative;
    padding-bottom: 100px; /* 为底部菜单和信息提示条留出空间 */
  }
  
  .qrcode-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .qrcode-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .info-bar {
    position: fixed;
    bottom: 50px; /* 假设底部菜单高度为50px */
    left: 0;
    right: 0;
    background-color: #ffcc00;
    color: #333;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    z-index: 100; /* 确保在其他元素之上 */
  }
  
  /* 确保 BottomMenu 组件保持在底部 */
  ::v-deep .bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101; /* 确保在信息提示条之上 */
  }
  </style>