<template>
  <!-- 整体容器 -->
  <div class="captain-center">
    <!-- 顶部通知栏 -->
    <div class="notification-bar">
      <i class="weui-icon-info"></i>
      <span>通知：新团长请添加客服微信</span>
    </div>

    <!-- 面包屑导航 -->
    <div class="breadcrumb">
      <a href="#">个人中心</a> › 团长中心
    </div>

    <!-- 菜单栏 -->
    <div class="weui-navbar">
      <div class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'captainCenter' }" @click="activeTab = 'captainCenter'">
        团长中心
      </div>
      <div class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'teamMembers' }" @click="activeTab = 'teamMembers'">
        团队成员
      </div>
      <div class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'dailyReport' }" @click="activeTab = 'dailyReport'">
        每日报表
      </div>
    </div>

    <!-- 加载指示器 -->
    <div v-if="loading" class="loading-indicator">
      加载中...
    </div>

    <!-- 团长中心内容 -->
    <div v-if="!loading && activeTab === 'captainCenter'">
      <!-- 钱包余额 -->
      <div class="balance-info">
        <h2>钱包余额</h2>
        <p class="balance">{{ balance }}</p>
        <p class="today-earnings">昨天: +{{ yesterdayEarnings }} 今天: +{{ todayEarnings }}</p>
        <button class="weui-btn weui-btn_primary" @click="handleWithdraw">提现</button>
      </div>

      <!-- 邀请链接 -->
      <div class="invite-section">
        <p>团队新增人员: 昨天: +{{ yesterdayAddMembers }} 今天: +{{ todayAddMembers }}</p>
        <input type="text" class="weui-input invite-link" v-model="inviteLink" readonly />
        <button class="weui-btn weui-btn_default" @click="copyInviteLink">一键复制邀请链接</button>
        <div class="weui-cell__ft"></div> <!-- 添加分隔 -->
        <a href="#" @click.prevent="confirmResetInviteLink">我要重置邀请链接，旧链接作废 >></a>
      </div>

      <!-- 提交通过率 -->
      <div class="success-rate">
        <p>团队提交通过率</p>
        <span class="rate">昨日: {{ teamYesterdayPassRate }}%</span>
        <span class="rate">今日: {{ teamTodayPassRate }}%</span>
        <span class="rate">近七天: {{ teamPassLastSevenDays }}%</span>
        <p class="rate-note">团队提交通过率低于90%，取消团长资格</p>
      </div>
    </div>

    <!-- 团队成员列表 -->
    <div v-if="!loading && activeTab === 'teamMembers'" class="team-members-section">
      <!-- 搜索框 -->
      <div class="weui-search-bar">
        <div class="weui-search-bar__form">
          <input
            type="text"
            class="weui-input"
            placeholder="搜索团队成员"
            v-model="searchQuery"
            @input="searchTeamMembers"
          />
        </div>
      </div>

      <div class="weui-panel weui-panel_access">
        <div class="weui-panel__hd">团队成员</div>
        <div class="weui-panel__bd">
          <div
            v-for="(member, index) in paginatedTeamMembers"
            :key="index"
            class="weui-media-box weui-media-box_text"
          >
            <h4 class="weui-media-box__title">{{ member.name }}</h4>
            <p class="weui-media-box__desc">通过率: {{ member.successRate }}%</p>
            <p class="weui-media-box__desc">累计完成: {{ member.totalCompleted }}</p>
            <p class="weui-media-box__desc">团长: {{ member.leaderName }}</p>
            <p class="weui-media-box__desc">子团累计完成: {{ member.subTeamTotalCompleted }}</p>
          </div>
        </div>
      </div>

      <!-- 分页按钮 -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="weui-btn weui-btn_default">上一页</button>
        <span>第 {{ currentPage }} 页</span>
        <button @click="nextPage" :disabled="!hasMorePages" class="weui-btn weui-btn_default">下一页</button>
      </div>
    </div>

    <!-- 底部菜单 -->
    <BottomMenu />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import BottomMenu from "@/components/BottomMenu.vue";
import api from '@/api';
import weui from 'weui.js';

export default {
  name: "CaptainCenter",
  components: {
    BottomMenu,
  },
  setup() {
    const router = useRouter();
    const balance = ref(0);
    const successRate = ref(0);
    const inviteLink = ref("");
    const yesterdayEarnings = ref(0);
    const todayEarnings = ref(0);
    const yesterdayAddMembers = ref(0);
    const todayAddMembers = ref(0);
    const teamYesterdayPassRate = ref(0);
    const teamTodayPassRate = ref(0);
    const teamPassLastSevenDays = ref(0);
    const activeTab = ref("captainCenter");
    const teamMembers = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const searchQuery = ref("");
    const loading = ref(true);

    const fetchCaptainInfo = async () => {
      try {
        const response = await api.get('/user/leader-info/');
        const data = response.data;
        balance.value = data.leader_balance;
        successRate.value = data.team_pass_last_seven_days;
        inviteLink.value = data.invitation_code;
        yesterdayEarnings.value = data.yesterday_add_balance;
        todayEarnings.value = data.today_add_balance;
        yesterdayAddMembers.value = data.yesterday_add_members;
        todayAddMembers.value = data.today_add_members;
        teamYesterdayPassRate.value = data.team_yesterday_pass_rate;
        teamTodayPassRate.value = data.team_today_pass_rate;
        teamPassLastSevenDays.value = data.team_pass_last_seven_days;
      } catch (error) {
        console.error("Error fetching captain info:", error);
        if (error.response && (error.response.status === 403 || error.response.status === 404)) {
          router.push({ name: 'upgradecontact' });
        }
      } finally {
        loading.value = false;
      }
    };

    const fetchTeamMembers = async () => {
      // 假设有一个API端点来获取团队成员
      try {
        const response = await api.get('/team-members/');
        teamMembers.value = response.data;
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    onMounted(() => {
      fetchCaptainInfo();
      fetchTeamMembers();
    });

    const paginatedTeamMembers = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = start + pageSize.value;
      return filteredTeamMembers.value.slice(start, end);
    });

    const filteredTeamMembers = computed(() => {
      if (!searchQuery.value) {
        return teamMembers.value;
      }
      return teamMembers.value.filter((member) =>
        member.name.includes(searchQuery.value)
      );
    });

    const hasMorePages = computed(() => {
      return currentPage.value * pageSize.value < filteredTeamMembers.value.length;
    });

    const nextPage = () => {
      if (hasMorePages.value) {
        currentPage.value += 1;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
      }
    };

    const searchTeamMembers = () => {
      currentPage.value = 1; // 搜索时重置到第一页
    };

    const copyInviteLink = () => {
      const fullInviteLink = `${window.location.origin}/register?invitation_code=${inviteLink.value}`;
      navigator.clipboard.writeText(fullInviteLink).then(() => {
        weui.toast('邀请链接已复制到剪贴板', 3000);
      });
    };

    const handleWithdraw = () => {
      router.push({ path: '/userwithdraw', query: { isLeader: 'true' } });
    };

    const confirmResetInviteLink = () => {
      weui.confirm('重置邀请码后，旧的邀请码将立即失效', {
        title: '确认重置邀请码',
        buttons: [
          {
            label: '取消',
            type: 'default',
            onClick: () => console.log('取消')
          },
          {
            label: '确认',
            type: 'primary',
            onClick: resetInviteLink
          }
        ]
      });
    };

    const resetInviteLink = async () => {
      try {
        const response = await api.post('/user/refresh_invite_code/');
        inviteLink.value = response.data.new_invite_code;
        weui.toast('邀请码已重置', 3000);
      } catch (error) {
        console.error("Error resetting invite link:", error);
      }
    };

    return {
      balance,
      successRate,
      inviteLink,
      yesterdayEarnings,
      todayEarnings,
      yesterdayAddMembers,
      todayAddMembers,
      teamYesterdayPassRate,
      teamTodayPassRate,
      teamPassLastSevenDays,
      activeTab,
      teamMembers,
      currentPage,
      pageSize,
      searchQuery,
      paginatedTeamMembers,
      filteredTeamMembers,
      hasMorePages,
      nextPage,
      prevPage,
      searchTeamMembers,
      copyInviteLink,
      loading,
      handleWithdraw,
      confirmResetInviteLink,
    };
  },
};
</script>

<style scoped>
.captain-center {
  padding: 20px;
  background-color: #f0f0f0;
  min-height: 100vh;
}

.notification-bar {
  padding: 10px;
  background-color: #eaf6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.breadcrumb {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.weui-navbar {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.weui-navbar__item {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  background-color: #f8f8f8;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.weui-bar__item_on {
  background-color: #1aad19;
  color: #333; /* 保持文字为黑色 */
}

.balance-info,
.invite-section,
.success-rate {
  text-align: center;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.balance {
  font-size: 32px;
  color: green;
  margin: 10px 0;
}

.today-earnings,
.rate-note {
  color: #666;
}

.rate {
  font-size: 32px;
  color: green;
  margin: 10px 0;
}

.weui-btn {
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.loading-indicator {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 20px;
}

.weui-cell__ft {
  margin: 20px 0; /* 添加一些间距 */
}

.invite-link {
  font-size: 25px;
  text-align: center;
  margin: 10px 0;
}
</style>