
<template>
  <!-- 整体容器 -->
  <div class="task-get-container">
    <!-- 顶部通知栏 -->
    <div class="notification-bar">
      <i class="weui-icon-info"></i>
      <span>通知：超过1分钟没找到，就放弃拿新的，不要浪费时间</span>
    </div>

    <!-- 页面标题 -->
    <div class="header">
      <h2>领取任务</h2>
      <p>点击按钮随机领取一个新任务</p>
    </div>



    <!-- 加载动画和按钮 -->
    <div class="get-task-button">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="loading-animation" key="loading">
          <p>正在为您分配任务...</p>
          <div class="loader"></div>
        </div>
      </transition>

      <button
        v-if="!loading"
        class="weui-btn weui-btn_primary"
        @click="getTask"
      >
        领取新任务
      </button>
    </div>


    <!-- WeUI Toast 提示 -->
    <div v-if="showNoTaskToast" class="weui-toast weui-toast_text">
      <p class="weui-toast_content">{{ noTaskMessage }}</p>
    </div>

    <!-- 底部导航栏 -->
    <BottomMenu />
  </div>
</template>

<script>
import confetti from "canvas-confetti";
import BottomMenu from "@/components/BottomMenu.vue";
import { ref } from 'vue';
import api from '@/api';
import { useRouter } from 'vue-router';

export default {
  components: {
    BottomMenu,
  },
  setup() {
    const task = ref(null);  // 存储任务信息
    const loading = ref(false);  // 处理加载状态
    const taskReceived = ref(false);  // 是否成功领取任务
    const showNoTaskToast = ref(false);  // 无任务时的提示
    const noTaskMessage = ref('');  // 无任务或用户拉黑提示
    const router = useRouter();  // 路由器实例

    // 领取任务的核心逻辑
    const getTask = async () => {
      loading.value = true;

      try {
        const response = await api.post('/user/task/pickup/');  // 请求API
        task.value = response.data;

        if (task.value) {
          taskReceived.value = true;
          // 礼花效果
          showConfetti();
          // 成功领取任务后跳转到 Mission 页面，并通过 params 传递任务数据
          setTimeout(() => {
            router.push({
              name: 'mission',
              state: { task: task.value },  // 使用 state 传递任务
            });
          }, 800);
        } else {
          noTaskMessage.value = '当前暂时无任务，请稍后重试或等团长通知';
          showNoTaskToast.value = true;
          setTimeout(() => {
            showNoTaskToast.value = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          noTaskMessage.value = '当前没有可领取的任务，'; //拉黑了 
        } else {
          noTaskMessage.value = '任务领取失败，请稍后再试';
        }
        showNoTaskToast.value = true;
        setTimeout(() => {
          showNoTaskToast.value = false;
        }, 5000);
      } finally {
        loading.value = false;
      }
    };

    // 礼花效果
    const showConfetti = () => {
      const end = Date.now() + 2 * 1000;
      const colors = ["#bb0000", "#ffffff", "#0000ff"];

      (function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    };

    return {
      task,
      loading,
      taskReceived,
      showNoTaskToast,
      noTaskMessage,
      getTask,  // 将 getTask 方法返回到模板中，以便按钮可以使用
    };
  }
};
</script>


<style scoped>
.task-get-container {
  padding: 20px;
  padding-top: 60px; /* 确保内容不被通知栏遮挡 */
}

.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #eaf6ff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1000;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 24px;
  color: #333;
}

.header p {
  color: #666;
}

.task-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.task-card:hover {
  transform: translateY(-10px);
}

.task-image img {
  width: 100%;
  border-radius: 8px;
}

.task-info {
  text-align: left;
  margin-top: 15px;
}

.task-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.get-task-button {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-animation {
  text-align: center;
}

.loading-animation p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1aad19;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.success-message {
  margin-top: 20px;
  color: #1aad19;
  font-weight: bold;
}

.weui-toast_text {
  position: fixed;
  bottom: 300px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.weui-btn_primary {
  background-color: #1aad19; /* 微信绿色 */
}
</style>
