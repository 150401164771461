<template>
  <div class="weui-tabbar">
    <router-link
      to="/"
      class="weui-tabbar__item"
      :class="{ 'weui-bar__item_on': $route.path === '/' }"
    >
      <div class="weui-tabbar__icon">
        <img src="@/assets/home.svg" alt="首页" class="tabbar-icon" />
      </div>
      <p class="weui-tabbar__label">首页</p>
    </router-link>

    <router-link
      to="/captaincenter"
      class="weui-tabbar__item"
      :class="{ 'weui-bar__item_on': $route.path === '/captaincenter' }"
    >
      <div class="weui-tabbar__icon">
        <img src="@/assets/group.svg" alt="团长中心" class="tabbar-icon" />
      </div>
      <p class="weui-tabbar__label">团长中心</p>
    </router-link>

    <router-link
      to="/personalcenter"
      class="weui-tabbar__item"
      :class="{ 'weui-bar__item_on': $route.path === '/personalcenter' }"
    >
      <div class="weui-tabbar__icon">
        <img src="@/assets/profile.svg" alt="个人中心" class="tabbar-icon" />
      </div>
      <p class="weui-tabbar__label">个人中心</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BottomMenu",
};
</script>

<style scoped>
.weui-tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
}

.weui-tabbar__icon {
  width: 24px;
  height: 24px;
}

.tabbar-icon {
  width: 24px;
  height: 24px;
}

.weui-tabbar__label {
  font-size: 12px;
}

.weui-bar__item_on {
  color: #1aad19; /* 修改为你想要的高亮颜色 */
}
</style>
