import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // 引入 router 配置

import 'weui';  // 引入 WeUI 的样式

// 创建 Vue 3 应用实例
const app = createApp(App);

// 挂载路由
app.use(router);

// 挂载应用到 #app 元素
app.mount('#app');
