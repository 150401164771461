<template>
  <div class="personal-center">
    <!-- 路径面包屑 -->
    <div class="breadcrumb">
      <a href="#">个人中心</a> &gt; <span>账户信息</span>
    </div>

    <!-- 菜单栏 -->
    <div class="weui-navbar">
      <a class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'account' }" @click="setActiveTab('account')">
        账户信息
      </a>
      <a class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'report' }" @click="setActiveTab('report')">
        每日报表
      </a>
      <a class="weui-navbar__item" :class="{ 'weui-bar__item_on': activeTab === 'records' }" @click="setActiveTab('records')">
        提交记录
      </a>
    </div>

    <!-- 账户信息 Tab -->
    <div v-if="activeTab === 'account'" class="weui-cells__title">账户信息</div>
    <div v-if="activeTab === 'account'" class="weui-cells">
      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">钱包余额</label>
        </div>
        <div class="weui-cell__bd">
          <span class="balance">{{ balance }}</span>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">手机号</label>
        </div>
        <div class="weui-cell__bd">
          <span>{{ phone }}</span>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">姓名</label>
        </div>
        <div class="weui-cell__bd">
          <span>{{ name }}</span>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__hd">
          <label class="weui-label">上级团长</label>
        </div>
        <div class="weui-cell__bd">
          <span>{{ leaderName }}</span>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__bd">
          <label class="weui-label">今日通过率</label>
          <span class="success-rate">{{ todayPassRate }}%</span>
        </div>
      </div>
      
      <div class="weui-cell">
        <div class="weui-cell__bd">
          <label class="weui-label">昨日通过率</label>
          <span class="success-rate">{{ yesterdayPassRate }}%</span>
        </div>
      </div>

      <div class="weui-cell">
        <div class="weui-cell__bd">
          <label class="weui-label">近7天通过率</label>
          <span class="success-rate">{{ lastSevenDaysPassRate }}%</span>
        </div>
      </div>
      <div class="weui-cell__ft note">
        提交通过率低于90%时无法接单
      </div>
    </div>
    
    <!-- 提现按钮 -->
    <div v-if="activeTab === 'account'" class="weui-btn-area">
      <button class="weui-btn weui-btn_primary" @click="handleWithdraw">提现</button>
    </div>

    <!-- 每日报表 Tab -->
    <div v-if="activeTab === 'report'" class="weui-cells__title">每日报表</div>
    <div v-if="activeTab === 'report'" class="weui-cells">
      <div class="weui-cell">
        <div class="weui-cell__bd">
          <p>等待系统更新统计数据...</p>
        </div>
      </div>
    </div>

    <!-- 提交记录 Tab -->
    <div v-if="activeTab === 'records'" class="weui-cells__title">提交记录</div>
    <div v-if="activeTab === 'records'" class="weui-cells">
      <div class="weui-cell">
        <div class="weui-cell__bd">
          <p>等待系统更新统计数据...</p>
        </div>
      </div>
    </div>

    <!-- 底部菜单 -->
    <BottomMenu />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import BottomMenu from "@/components/BottomMenu.vue";
import api from '@/api';

export default {
  name: "PersonalCenter",
  components: {
    BottomMenu,
  },
  setup() {
    const router = useRouter();
    const activeTab = ref('account');
    const balance = ref(0);
    const phone = ref('');
    const name = ref('');
    const leaderName = ref('');
    const successRate = ref(0);
    const todayPassRate = ref(0);
    const yesterdayPassRate = ref(0);
    const lastSevenDaysPassRate = ref(0);

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    const fetchUserProfile = async () => {
      try {
        const response = await api.get('user/profile/');  // 确保路径与Django配置一致
        const data = response.data;
        balance.value = data.user_balance;
        phone.value = data.phone;
        name.value = data.name;
        leaderName.value = data.leader_name;
        successRate.value = data.success_rate;
        todayPassRate.value = data.today_pass_rate;
        yesterdayPassRate.value = data.yesterday_pass_rate;
        lastSevenDaysPassRate.value = data.last_seven_days_pass_rate;
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    };

    const handleWithdraw = () => {
      router.push({ path: '/userwithdraw', query: { isLeader: 'false' } });
    };

    onMounted(() => {
      fetchUserProfile();
    });

    return {
      activeTab,
      balance,
      phone,
      name,
      leaderName,
      successRate,
      todayPassRate,
      yesterdayPassRate,
      lastSevenDaysPassRate,
      setActiveTab,
      handleWithdraw,
    };
  },
};
</script>

<style scoped>
.personal-center {
  min-height: 100vh;
  padding: 20px;
  background-color: #F5F5F5; /* 整个背景使用浅灰色 */
}

.breadcrumb {
  margin-bottom: 15px;
  font-size: 14px;
  color: #666;
}

.weui-navbar__item {
  font-size: 16px;
}

.balance {
  color: #1AAD19;
  font-size: 26px;
  font-weight: bold;
}

.success-rate {
  color: #1AAD19;
  font-size: 18px;
}

.note {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.weui-btn-area {
  margin-top: 30px;
}

.weui-btn_primary {
  background-color: #1AAD19;
  border-radius: 5px;
}
</style>