<template>
  <div id="app">
    <router-view />  <!-- 这个是关键，它会显示当前路由匹配的页面 -->
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* 你可以删除不需要的默认样式 */
</style>
