<template>
  <!-- 插入 SVG Logo -->
  <div class="logo-container">
    <img src="@/assets/logo.png" alt="Logo" class="logo"/>
    <h3 class="logo-title">肥羊学堂</h3>
  </div>

  <div class="weui-form">
    <div class="weui-form__text-area">
      <h2 class="weui-form__title">登录</h2>
      <p class="weui-form__desc">请输入您的手机号和密码</p>
    </div>

    <div class="weui-form__control-area">
      <!-- 手机号输入框 -->
      <div class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">手机号</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="phone" type="tel" class="weui-input" placeholder="请输入手机号" />
        </div>
      </div>

      <!-- 密码输入框 -->
      <div class="weui-cell weui-cell_active weui-cell_form">
        <div class="weui-cell__hd">
          <label class="weui-label">密码</label>
        </div>
        <div class="weui-cell__bd">
          <input v-model="password" type="password" class="weui-input" placeholder="请输入密码" />
        </div>
      </div>
    </div>

    <!-- 错误提示信息 -->
    <div class="weui-form__tips-area" v-if="errorMessage">
      <div class="weui-tips weui-tips_warn">{{ errorMessage }}</div>
    </div>

    <!-- 登录按钮 -->
    <div class="weui-form__opr-area">
      <button class="weui-btn weui-btn_primary" @click="login">登录</button>
    </div>

    <!-- 帮助提示 -->
    <div class="weui-form__tips-area">
      <div class="weui-tips">遇到问题？<a href="#" @click="showContactDialog">联系客服</a></div>
    </div>

    <!-- 注册页面跳转链接 -->
    <div class="register-link">
      <p>没有账号？<router-link to="/register" class="register-router-link">去注册</router-link></p>
    </div>

    <!-- 账户被封禁提示框 -->
    <div v-if="showBanDialog" class="weui-dialog__wrapper">
      <div class="weui-dialog">
        <div class="weui-dialog__hd">
          <strong class="weui-dialog__title">账户被封禁</strong>
        </div>
        <div class="weui-dialog__bd">
          该账户已被封禁，无法登录。
        </div>
        <div class="weui-dialog__ft">
          <a href="#" class="weui-dialog__btn weui-dialog__btn_primary" @click="closeBanDialog">确认</a>
        </div>
      </div>
    </div>

    <!-- 联系客服的对话框 -->
    <div v-if="showDialog" class="weui-dialog__wrapper">
      <div class="weui-dialog">
        <div class="weui-dialog__hd">
          <strong class="weui-dialog__title">联系客服</strong>
        </div>
        <div class="weui-dialog__bd">
          联系QQ：123456
          <!-- 一键复制按钮 -->
          <div class="copy-btn-container">
            <button class="weui-btn weui-btn_mini weui-btn_default" @click="copyToClipboard('123456')">复制QQ号</button>
          </div>
          <div v-if="copySuccess" class="weui-tips weui-tips_success">已复制到剪贴板</div>
        </div>
        <div class="weui-dialog__ft">
          <a href="#" class="weui-dialog__btn weui-dialog__btn_primary" @click="closeDialog">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
export default {
  name: 'LoginPage',
  data() {
    return {
      phone: '',
      password: '',
      errorMessage: '',
      showDialog: false,  // 控制联系客服对话框显示的状态
      showBanDialog: false,  // 控制账户封禁提示框显示的状态
      copySuccess: false  // 控制复制成功提示的状态
    };
  },
  beforeRouteEnter(to, from, next) {
    document.title = '登录 - 肥羊学堂';  // 动态设置页面标题
    next();
  },
  methods: {
    async login() {
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.phone) || this.phone.length !== 11) {
        this.errorMessage = '请输入有效的11位中国手机号';
        return;
      }

      try {
        const response = await api.post('/user/login/', {
          phone: this.phone,
          password: this.password
        });

        // 处理成功登录
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        this.$router.push({ name: 'home' });
      } catch (error) {
        console.error('Login error:', error);  // 输出错误日志，便于调试

        // 检查 error.response 和 error.response.data 是否存在
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.detail;
          if (errorMessage) {
            if (errorMessage.includes('账户已被封禁')) {
              this.showBanDialog = true;
              return;
            } else if (errorMessage.includes('手机号未注册')) {
              this.errorMessage = '手机号未注册';
              return;
            } else if (errorMessage.includes('密码错误')) {
              this.errorMessage = '密码错误';
              return;
            }
          }
        }
        // 如果没有合适的错误信息，显示通用错误提示
        this.errorMessage = '登录失败，请检查手机号和密码';
      }
    },
    closeBanDialog() {
      this.showBanDialog = false;
    },
    showContactDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.copySuccess = false;  // 关闭弹窗时重置复制成功状态
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copySuccess = true;  // 显示复制成功提示
      }).catch(err => {
        console.error('复制失败', err);
      });
    }
  }
  
};
</script>

<style scoped>
/* WeUI 样式 */
.weui-form {
  max-width: 400px;
  margin: 20px auto;
}

.weui-btn_primary {
  background-color: #1AAD19; /* 微信绿色 */
}

.error {
  color: red;
}

.weui-tips_warn {
  color: red;
}

/* 对话框样式 */
.weui-dialog__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.weui-dialog {
  background-color: white;
  width: 300px;
  border-radius: 5px;
}

.weui-dialog__hd {
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.weui-dialog__bd {
  padding: 20px;
  text-align: center;
}

.copy-btn-container {
  margin-top: 15px;
}

.weui-tips_success {
  color: green;
}

.weui-dialog__ft {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.weui-dialog__btn {
  color: #1AAD19;
  font-weight: bold;
  cursor: pointer;
}

/* 控制 SVG Logo 的显示位置 */
.logo-container {
  margin-top: 40px; /* 新增：距离顶部 30 像素 */
  margin-bottom: 10px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.logo {
  width: 80px;
  height: auto;
}

/* 标题样式 */
.logo-title {
  margin-top: 10px; /* 使标题和 Logo 之间有一些间距 */
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

/* SVG 和标题居中对齐 */
.logo-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

/* 注册链接样式 */
.register-link {
  margin-top: 20px;
  text-align: center; /* 使注册链接居中 */
}

.register-router-link {
  color: #1AAD19;
  text-decoration: none;
  font-weight: bold;
}
</style>
